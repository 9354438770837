<template>
  <div>
    <v-btn
      :loading="loading"
      color="primary"
      outlined
      @click="onButtonClick"
    >
      <v-icon left>
        fa-image
      </v-icon>
      Upload A Photo
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @input="signAndUploadFiles"
      multiple
    >
  </div>
</template>

<script>
import TEMP_FILE from '../../graphql/Temp-Upload/tempSignedUrl.gql';

export default {
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    multiple: Boolean,
    parentId: Number,
  },

  data() {
    return {
      loading: false,
      freeUserPhotoLimit: 1,
      paidUserPhotoLimit: 8,
    };
  },

  computed: {
    permissions() {
      return this.$store.state.auth.permissions;
    },

    photoCount() {
      return this.files.length;
    },

    photoLimit() {
      // @TODO we should probably move this to a prop that can be controlled from the parent component
      return this.permissions && this.permissions['proposal.item.add.photo'] ? this.paidUserPhotoLimit : this.freeUserPhotoLimit;
    },

    photoLimitReachedMessage() {
      return this.photoLimit === 1
        ? `Your photo limit is ${this.freeUserPhotoLimit}. Upgrade your account to upload up to ${this.paidUserPhotoLimit} photos per line item.`
        : `You may upload up to ${this.paidUserPhotoLimit} photos per line item.`;
    },
  },

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },

    signAndUploadFiles(e) {
      this.loading = true;
      const providedFiles = e.target.files;

      if ((this.photoCount + providedFiles.length) > this.photoLimit) {
        this.$store.commit('notify', {
          color: 'error',
          message: this.photoLimitReachedMessage,
          timeout: 5000,
        });

        this.loading = false;
        return;
      }

      providedFiles.forEach(async (fileToUpload, index, array) => {
        let signedUrl;

        // Create signed url
        await this.$apollo.mutate({
          mutation: TEMP_FILE,
          variables: {
            name: fileToUpload.name.trim().split(' ').join('-'),
          },
          update(store, { data }) {
            signedUrl = data.tempSignedURL.url;
          },
        });

        // Put image file in S3
        await fetch(signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'binary/octet-stream',
          },
          body: fileToUpload,
        });

        // Create and emit image object to attach
        const imageObject = {
          alt: `Photo of ${fileToUpload.name}`,
          height: 100,
          title: fileToUpload.name,
          url: signedUrl.split('?').shift(),
          width: 100,
        };

        this.$emit('fileChanged', imageObject);

        if (index === array.length - 1) {
          this.loading = false;
        }
      });
    },
  },
};
</script>
