var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: { loading: _vm.loading, color: "primary", outlined: "" },
          on: { click: _vm.onButtonClick }
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [_vm._v(" fa-image ")]),
          _vm._v(" Upload A Photo ")
        ],
        1
      ),
      _c("input", {
        ref: "uploader",
        staticClass: "d-none",
        attrs: { type: "file", accept: "image/*", multiple: "" },
        on: { input: _vm.signAndUploadFiles }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }